#cssmenu2,
#cssmenu2 ul,
#cssmenu2 ul li,
#cssmenu2 ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family:objective;

}
#cssmenu2 {
	width: 250px;
	margin: 0 0 100px 0;
	font-family: Helvetica, Arial, sans-serif;
	color: #ffffff;
}
#cssmenu2 ul ul {
  display: none;
}
.align-right {
  float: right;
}
#cssmenu2 > ul > li > a {
  padding: 15px 20px;
 /* border-left: 1px solid #1682ba;
  border-right: 1px solid #1682ba;
  border-top: 1px solid #1682ba;*/
  cursor: pointer;
  z-index: 2;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
 /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  background: #36aae7;
  background: -webkit-linear-gradient(#36aae7, #1fa0e4);
  background: -moz-linear-gradient(#36aae7, #1fa0e4);
  background: -o-linear-gradient(#36aae7, #1fa0e4);
  background: -ms-linear-gradient(#36aae7, #1fa0e4);
  background: linear-gradient(#36aae7, #1fa0e4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);*/
}
#cssmenu2 > ul > li > a:hover,
#cssmenu2 > ul > li.active > a,
#cssmenu2 > ul > li.open > a {
  color: #25441f;
 /* background: #1fa0e4;
  background: -webkit-linear-gradient(#1fa0e4, #1992d1);
  background: -moz-linear-gradient(#1fa0e4, #1992d1);
  background: -o-linear-gradient(#1fa0e4, #1992d1);
  background: -ms-linear-gradient(#1fa0e4, #1992d1);
  background: linear-gradient(#1fa0e4, #1992d1);*/
}
#cssmenu2 > ul > li.open > a {
 /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #1682ba;*/
}
#cssmenu2 > ul > li:last-child > a,
#cssmenu2 > ul > li.last > a {
  /*border-bottom: 1px solid #1682ba;*/
}

.holder {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.holder::after,
.holder::before {
  display: block;
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  right: 20px;
  z-index: 10;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.holder::after {
  top: 17px;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}
#cssmenu2 > ul > li > a:hover > span::after,
#cssmenu2 > ul > li.active > a > span::after,
#cssmenu2 > ul > li.open > a > span::after {
  border-color: #25441f;
}
.holder::before {
  top: 18px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-color: inherit;
  border-left-color: inherit;
}
#cssmenu2 ul ul li a {
  cursor: pointer;
  /*border-bottom: 1px solid #32373e;
  border-left: 1px solid #32373e;
  border-right: 1px solid #32373e;*/
  padding: 10px 20px;
  z-index: 1;
  text-decoration: none;
  font-size: 13px;
  color: #333;
 /* background: #49505a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);*/
}
#cssmenu2 ul ul li:hover > a,
#cssmenu2 ul ul li.open > a,
#cssmenu2 ul ul li.active > a {
  /*background: #424852;*/
  color: #25441f;
}
#cssmenu2 ul ul li:first-child > a {
  box-shadow: none;
}
#cssmenu2 ul ul ul li:first-child > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
#cssmenu2 ul ul ul li a {
  padding-left: 30px;
}
#cssmenu2 > ul > li > ul > li:last-child > a,
#cssmenu2 > ul > li > ul > li.last > a {
  border-bottom: 0;
}
#cssmenu2 > ul > li > ul > li.open:last-child > a,
#cssmenu2 > ul > li > ul > li.last.open > a {
  /*border-bottom: 1px solid #32373e;*/
}
#cssmenu2 > ul > li > ul > li.open:last-child > ul > li:last-child > a {
  border-bottom: 0;
}
#cssmenu2 ul ul li.has-sub > a::after {
  display: block;
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  right: 20px;
  z-index: 10;
  top: 11.5px;
  border-top: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
#cssmenu2 ul ul li.active > a::after,
#cssmenu2 ul ul li.open > a::after,
#cssmenu2 ul ul li > a:hover::after {
  border-color: #333;
}














.holder {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
}

#cssmenu2,
#cssmenu2 ul,
#cssmenu2 ul li,
#cssmenu2 ul li a {
  list-style: none;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noticias-img {
  background-position: center;
  background-size: cover;
  margin-bottom: 15px;
  height: 300px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  // font-family: "lato";
}

#noticias1 p {
  font-family: objective;
  font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 24px;
}

#noticias1 h4 {
  font-size: 25px;
  font-weight: bold;
  font-family: mediator;
}